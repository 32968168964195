import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from "react-router-dom";
import ApiLoan from "../../service/ApiLoanService";
import { CompanyContext } from "../../context/companyContext"
import './MessengerIcon.css'

const MessageIcon = () => {
    const [notSeenConversationsWhatsapp, setNotSeenConversationsWhatsapp] = useState(0);
    const [notSeenConversationsInternalChats, setNotSeenConversationsInternalChats] = useState(0);
    const companyContext = useContext(CompanyContext);
    const socket = companyContext.socket;
    const apiLoan = new ApiLoan();
    const { internalChatEnabled } = companyContext;
    const badgeWhatsappClass = notSeenConversationsInternalChats !== 0 ? 'badge-whatsapp' : '';
    const badgeInternalChatsClass = notSeenConversationsWhatsapp !== 0 ? 'badge-internal-chats' : '';

    useEffect(() => {
        getNotSeenConversationsWhatsapp().then((response) => {
            setNotSeenConversationsWhatsapp(response.data.not_seen_conversations);
        })
        getNotSeenConversationsInternalChats().then((response) => {
            setNotSeenConversationsInternalChats(response.data.not_seen_internal_conversations);
        })
    },[companyContext.privateInbox]);

    useEffect(() => {
        socket.on('not-seen-conversations', updateNotSeenConversationWhatsapp)
        socket.on( 'not-seen-internal-conversations-' + localStorage.getItem('username') + '-' + localStorage.getItem('provider_id'), updateNotSeenConversationInternalChats);
        return () => { 
            socket.off('not-seen-conversations', updateNotSeenConversationWhatsapp)
            socket.off( 'not-seen-internal-conversations-' + localStorage.getItem('username') + '-' + localStorage.getItem('provider_id'), updateNotSeenConversationInternalChats);
        }
      },[companyContext.privateInbox]);

    const getNotSeenConversationsInternalChats = () => {
        return apiLoan.getResources({
            url: '/internal_conversations/not_seen'
        })
    }

    const getNotSeenConversationsWhatsapp = () => {
        return apiLoan.getResources({
            url: '/conversations/not_seen'
        })
    }

    const updateNotSeenConversationWhatsapp = () => {
        getNotSeenConversationsWhatsapp().then((response) => {
            setNotSeenConversationsWhatsapp(response.data.not_seen_conversations);
        })
    }

    const updateNotSeenConversationInternalChats = () => {
        getNotSeenConversationsInternalChats().then((response) => {
            setNotSeenConversationsInternalChats(response.data.not_seen_internal_conversations);
        })
    }

    const numberWhatsapp = (notSeenConversationsWhatsapp ? (<span style={{backgroundColor: '#24ba0d'}} className={`topbar-badge animated rubberBand ${badgeWhatsappClass}`}>{notSeenConversationsWhatsapp}</span>) : '')
    const numberInternalChat = (notSeenConversationsInternalChats ? (<span style={{backgroundColor: '#163774e5'}}className={`topbar-badge animated rubberBand ${badgeInternalChatsClass}`}>{notSeenConversationsInternalChats}</span>) : '')

    return (<button className="p-link">
                <NavLink to={"/"} >
                    <i className="pi pi-comments"></i>
                    {numberWhatsapp}
                    {internalChatEnabled && numberInternalChat}
                    <span className="topbar-item-name">Mensajes</span>
                </NavLink>
            </button>);
}

export default MessageIcon